
.report{
    .cardIcon {
        font-size: 60px;
        text-align: center;
    }
    .reportCard{
        margin: 15px 0;
        margin-right: 20px; 
    }
    .labels {
        font-size: 13px;
        text-align: center;
    }
    .speedSlider{
        margin-left: 35px; 
        margin-right: 35px;
    }
}

.generalReport{
    .cardWrapper{
        padding: 0 10px 20px;
        .cardHeader{
            padding: 0px 0px 0px 0px;
        } 
        .telephonesList{
            height: 300px;
            overflow-y: scroll;
        }
        .cardTitle{
            padding: 0 10px;
        }
        .toggleSwitch{
            margin: 5px 0px 0px 0px;
            float: right;
        }
        .tagsRow{
            padding: 5px 0px 0px 0px;
        }
        .divider{
            padding-top: 13px;
            font-size: 14px
        }
    }
    .searchBar{
        padding-top: 10px;
    }
    .cardsContainer{
        padding: 0 10px 20px 30px;
    }
    padding: 24px 24px 8px 24px   
}

.reportTitle{
    text-align: center;
    width: 100%;
    text-transform: capitalize;
    font-size: 20px;
    color: #565656;
}

.pagination{
    padding: 0px 0px 0px 30px;
}

.invoicesModal{
    .invoicesList{
        height: 300px;
        overflow-y: scroll;
        .productsList{
            left: 50px;
        }
    }
    .commentsList{
        height: 300px;
        overflow-y: scroll;
        .dateShow{
            font-size: 10px
        }
        
    }
    .addCommentInput{
        
        .searchBarButton{
            padding-left: 10px;
            padding-top: 3px;
        }
    }
}

.ExportReportMenu{
    .datePicker{
        padding-left: 65px;
    }
    .invoiceTypeTags{
        padding-left: 110px;
    }
}

.scrollableList{
    height: 300px;
    overflow-y: scroll;
    .commentMessage{
        padding-bottom: 5px
    }
}

.incomeReport{
    .entryCard{
        height: 44px;
        overflow: hidden;
        color: black;
        line-height: 22px;
    }

    .accountList{
        margin: 0 auto;
        overflow: auto;
        height: 700px;
        .details{
            margin: 0 55px auto;
            .ant-statistic-content-value-decimal {
                font-size: 14px;
            }

            .miniItemList {
                font-size: 10px;
                color: gray;
                font-variant: tabular-nums;
                font-variant-ligatures: normal;
                font-variant-caps: normal;
                font-variant-numeric: tabular-nums;
                font-variant-east-asian: normal;
                font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            }
        }
    }

    .equipmentTotal{
        margin: 0 auto;
        overflow: auto;
        .ant-statistic-content-value-decimal {
            font-size: 20px;
        }
    }

    .details{
        margin: 0 55px auto;

        .miniItemList {
            font-size: 10px;
            color: gray;
            font-variant: tabular-nums;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: tabular-nums;
            font-variant-east-asian: normal;
            font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        }
    }
}

.technician-report-chart-header{
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.technician-report-charts{
    padding: 10px;
}

.technician-report-range-button{
    margin: 5px;
}