.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-truck {
  width: 1.0357142857142856em;
}

.icon-automobile {
  width: 1.1428571428571428em;
}

.icon-paper-plane-o {
  width: 1.0005714285714284em;
}

.icon-battery {
  width: 1.2857142857142856em;
}

.icon-battery-3 {
  width: 1.2857142857142856em;
}

.icon-battery-2 {
  width: 1.2857142857142856em;
}

.icon-battery-1 {
  width: 1.2857142857142856em;
}

.icon-battery-0 {
  width: 1.2857142857142856em;
}

.icon-thermometer {
  width: 0.5714285714285714em;
}

.icon-thermometer-3 {
  width: 0.5714285714285714em;
}

.icon-thermometer-2 {
  width: 0.5714285714285714em;
}

.icon-thermometer-1 {
  width: 0.5714285714285714em;
}

.icon-thermometer-0 {
  width: 0.5714285714285714em;
}
