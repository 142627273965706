.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all .3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

.dynamic-extension-button {
  cursor: pointer;
  position: relative;
  top: 2px;
  font-size: 20px;
  transition: all .3s;
}