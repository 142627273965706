
.add-button-spacing {
    top: 0.35em;
}

.ant-list-header {
    text-align: center;
}

.employee-scrollable-list{
    height: 300px;
    overflow-y: scroll;
    margin-bottom: 0.35em!important;
    margin-top: 0.35em!important;
}

.add-button {
    margin: 0.35em!important;
}

.buttons-align-right {
    text-align: right;
}

.centered-list-header {
    text-align: center;
    display: inline-block;
    width: 100%;
}

.employee-upload {
    .ant-upload-select-picture-card {
        width: 95%!important;
    }
    .ant-upload-list-item {
        width: 95%!important;
    }
}

.employee-view-picture {
    margin: 1px!important;
    cursor: pointer;
}

.employee-picture-remove-margin {
    .ant-row {
        margin-bottom: 0;
    }
}

.employee-license-padding {
    .ant-row {
        padding-top: 7px;
    }
}

.employee-list-remove-button-padding {
    padding: 7px;
}

.employee-deductions-button {
    margin-top: 0.35em!important;
}