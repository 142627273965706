.dashboard-chart-header {
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.dashboard-full-height {
    height: 100%;
}

.dashboard-widget-card {
    .ant-card-body{
        padding: 0;
    }
}

.statistic-comparison-main-card {
    background: #ECECEC;
    padding-top: 2px;
    padding-bottom: 2px;
    .ant-card-body{
        padding: 24px;
    }
}

.dashboard-date-range-select {
    width: 100%!important;
    padding-top: 10px!important;
    .ant-select-selection{
        border: 0;
    }
    .ant-select-selection-selected-value{
        text-align: center;
        //font-weight: bold;
        float: none;
    }
    .ant-select-selection__rendered::after {
        content: none;
    }
}

.dashboard-scrollable-graph {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.dashboard-range-picker-option {
    text-align: center;
}

.dashboard-widget-height {
    .ant-spin-nested-loading {
        height: 100%;
    }
    .ant-spin-container {
        height: 100%;
    }
}

.dashboard-statistic-extra-content {
    margin-top: 2px!important;
    .ant-card-body{
        padding: 12px;
    }
}

.dashboard-graph-padding {
    padding-top: 20px;
}

.dashboard-widget-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}

.dashboard-invoice-payment-tooltip-title {
    font-weight: bold;
}

.dashboard-invoice-payment-tooltip-table {
    border-collapse: collapse;
    width: 100%;
    white-space: nowrap;
}

.dashboard-invoice-payment-tooltip-table-value {
    text-align: right;
}

.dashboard-tooltip-format {
    padding-top: 6px;
	padding-bottom: 6px;
}