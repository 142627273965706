.ColorPicker {
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin: 2px;
}
.mapConfig{
	.ant-row-flex{
		margin-bottom: 5px;
	}
	.rightCol{
		text-align: right;
	}
}