.login {
	.login-form-forgot {
		float: right;
	}
	.login-form-button {
		width: 100%;
	}
	.imageLogo {
		width: 100%;
	}
}