#app {
	height: 100%;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	white-space: nowrap !important;
}
.center-text {
	text-align: center;
}
.table {
	.control {
		padding: 5px 0 5px 0;
		.action {
			button:not(:first-child) {
			}
		}
		> button {
			margin-left: 10px;
		}
	}
	.status {
		text-align: center !important;
	}
}
.MobileTable {
	.ant-spin-container > div,
	.control {
		margin: 5px 0px;
	}
}
.sessionControl {
	padding-bottom: 5px;
}

.markerLabel {
	font-size: 14px;
	color: #fafafa;
	background: red;
	padding: 2px;
	border: 1px solid #000;
}
.profilePicture {
	img {
		border-radius: 100%;
	}
}
.profileModal {
	.profileMenu {
		display: none;
		min-height: 300px;
		padding: 5px;
		border-left: 1px solid #e9e9e9;
		&.selected {
			display: block;
		}
	}
	.profileNav {
		padding: 5px;
	}
	.menuLink {
		cursor: pointer;
		font-weight: bold;
		padding: 5px;
	}
	.myProfile {
		img {
			min-height: 150px;
			min-width: 150px;
			max-height: 150px;
			max-width: 150px;
		}
		.profileForm {
			display: none;
			.editProfileAction {
				text-align: center;
			}
		}
		.side-icon {
			cursor: pointer;
		}
		.profileView {
			display: block;
			img {
				border-radius: 100%;
			}
			.profileUpload {
				.ant-spin-container {
					overflow: visible !important;
				}
			}
			.ant-row-flex {
				padding: 10px 0px;
			}
			.avatar-uploader,
			.avatar-uploader-trigger,
			.loaded-img {
				width: 150px;
				height: 150px;
				&:hover {
					opacity: 0.7;
				}
				&:hover:before {
					content: "\E627";
					font-family: "anticon" !important;
					position: absolute;
					top: 55px;
					left: 65px;
					font-size: 30px;
				}
			}
			.avatar-uploader {
				display: block;
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
			}
			.avatar-uploader-trigger {
				display: table-cell;
				vertical-align: middle;
				font-size: 28px;
				color: #999;
			}
			.avatar-uploader,
			img {
				border-radius: 100%;
			}
		}
		&.edit {
			.profileForm {
				display: block;
			}
			.profileView {
				display: none;
			}
		}
	}
}

.full-component {
	width: 100% !important;
}

.almost-full-component {
	width: 95% !important;
}

.hide-component {
	display: none !important;
}

.extra-action-btn {
	margin-left: 16px;	
}