$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-units1: unquote('"\\e90e"');
$icon-units2: unquote('"\\e90f"');
$icon-units3: unquote('"\\e910"');
$icon-units4: unquote('"\\e911"');
$icon-earth: unquote('"\\e90b"');
$icon-beer: unquote('"\\e903"');
$icon-can: unquote('"\\e9e0"');
$icon-presentation: unquote('"\\e90a"');
$icon-map: unquote('"\\e90d"');
$icon-flag: unquote('"\\e906"');
$icon-flag2: unquote('"\\e907"');
$icon-strategy: unquote('"\\e904"');
$icon-checkered-flag: unquote('"\\e954"');
$icon-my_location: unquote('"\\e90c"');
$icon-sim_card: unquote('"\\e900"');
$icon-battery_alert: unquote('"\\e19c"');
$icon-flash_off: unquote('"\\e3e6"');
$icon-volume-mute: unquote('"\\e908"');
$icon-volume: unquote('"\\e908"');
$icon-audio: unquote('"\\e908"');
$icon-player: unquote('"\\e908"');
$icon-volume-high: unquote('"\\e909"');
$icon-volume2: unquote('"\\e909"');
$icon-audio2: unquote('"\\e909"');
$icon-speaker: unquote('"\\e909"');
$icon-player2: unquote('"\\e909"');
$icon-truck2: unquote('"\\e9b0"');
$icon-radio-tower: unquote('"\\f031"');
$icon-pencil: unquote('"\\e905"');
$icon-phone: unquote('"\\e942"');
$icon-address-book: unquote('"\\e944"');
$icon-envelop: unquote('"\\e945"');
$icon-location: unquote('"\\e947"');
$icon-location2: unquote('"\\e948"');
$icon-mobile: unquote('"\\e958"');
$icon-floppy-disk: unquote('"\\e962"');
$icon-redo2: unquote('"\\e968"');
$icon-user: unquote('"\\e971"');
$icon-users: unquote('"\\e972"');
$icon-user-tie: unquote('"\\e976"');
$icon-search: unquote('"\\e986"');
$icon-bin: unquote('"\\e9ac"');
$icon-switch: unquote('"\\e9b6"');
$icon-file-excel: unquote('"\\eae2"');
$icon-truck: unquote('"\\f0d1"');
$icon-automobile: unquote('"\\f1b9"');
$icon-car: unquote('"\\f1b9"');
$icon-paper-plane-o: unquote('"\\f1d9"');
$icon-send-o: unquote('"\\f1d9"');
$icon-motorcycle: unquote('"\\f21c"');
$icon-battery: unquote('"\\f240"');
$icon-battery-4: unquote('"\\f240"');
$icon-battery-full: unquote('"\\f240"');
$icon-battery-3: unquote('"\\f241"');
$icon-battery-three-quarters: unquote('"\\f241"');
$icon-battery-2: unquote('"\\f242"');
$icon-battery-half: unquote('"\\f242"');
$icon-battery-1: unquote('"\\f243"');
$icon-battery-quarter: unquote('"\\f243"');
$icon-battery-0: unquote('"\\f244"');
$icon-battery-empty: unquote('"\\f244"');
$icon-thermometer: unquote('"\\f2c7"');
$icon-thermometer-4: unquote('"\\f2c7"');
$icon-thermometer-full: unquote('"\\f2c7"');
$icon-thermometer-3: unquote('"\\f2c8"');
$icon-thermometer-three-quarters: unquote('"\\f2c8"');
$icon-thermometer-2: unquote('"\\f2c9"');
$icon-thermometer-half: unquote('"\\f2c9"');
$icon-thermometer-1: unquote('"\\f2ca"');
$icon-thermometer-quarter: unquote('"\\f2ca"');
$icon-thermometer-0: unquote('"\\f2cb"');
$icon-thermometer-empty: unquote('"\\f2cb"');
$icon-warning: unquote('"\\e901"');
$icon-warning2: unquote('"\\e902"');
$icon-search2: unquote('"\\f002"');
$icon-envelope-o: unquote('"\\f003"');
$icon-user2: unquote('"\\f007"');
$icon-check: unquote('"\\f00c"');
$icon-close: unquote('"\\f00d"');
$icon-remove: unquote('"\\f00d"');
$icon-times: unquote('"\\f00d"');
$icon-trash-o: unquote('"\\f014"');
$icon-ban: unquote('"\\f05e"');
$icon-mail-forward: unquote('"\\f064"');
$icon-share: unquote('"\\f064"');
$icon-group: unquote('"\\f0c0"');
$icon-users2: unquote('"\\f0c0"');
$icon-circle: unquote('"\\1f5cc"');
$icon-paper-plane-o2: unquote('"\\f1da"');
$icon-send-o2: unquote('"\\f1da"');
$icon-podcast: unquote('"\\f2ce"');

