.mainLayout {
	height: 100%;
	font-size: 14px;

	.trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color .3s;

		&:hover {
			color: #108ee9;
		}
	}
	.ant-dropdown-trigger {
		.ant-avatar {
			overflow: visible;
			margin-right: 5px;
		}
	}
	.text-right{
		text-align: right;
		.ant-dropdown-trigger{
			padding: 0 16px;
		}
	}
	.user-menu {
		font-size: 15px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color .3s;
		color: black;

		&hover {
			color: #108ee9;
		}
	}


	.logo {
		height: 32px;
		background: #333;
		border-radius: 6px;
		margin: 16px;
	}

	.ant-layout-sider-collapsed {
		.anticon {
			font-size: 16px;
		}
		.ant-menu-vertical{
			width: 100%;
		}
	}

	.header{
		background: #fff;
		padding: 0;
	}

	.content{
		margin: 10px 5px 5px;
		background: #fff;
		overflow: auto;
		
		.view{
			padding:24px;
		}
		&.mobileContent{
			overflow: hidden;
			overflow-y: scroll;
		}
	}
	.mobileMenuIcon{
		font-size: 30px;
		padding: 5px;
		margin: 5px;
	}

}
.popMenu{
	margin: 0px 5px;
	.ant-popover-arrow{
		&:after{
			border-bottom-color: #001529!important;
		}
	}
	.ant-popover-arrow{
		background: #001529!important;
	}
	.ant-popover-inner{
		background: #001529!important;
		.ant-popover-title, .side-icon, .nav-text {
			color: #c0c0c0!important;
		}
		.ant-popover-inner-content{
			padding: 0px;
		}
		.navRow {
			padding: 10px;
			cursor: pointer;
			&.selected {
				background: #108ee9;
				.ant-popover-title, .side-icon, .nav-text  {
					color: #fff!important;
				}
				
			}
		}
	}
}

.userMenu {
	margin-top: 33px!important;
}