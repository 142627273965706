.input-width{
	width: 50%;
}

.button-spacing{
	margin-right: 8px;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.rowHeight{
	height: 80px;
}

.tax-steps {
	border-right: 1px solid #e8e8e8;
	padding-right: 10px!important;
	.ant-steps-dot .ant-steps-item-content {
        width: 75%;
	}
}

.tax-add-content {
	padding-left: 10px!important;
}

.indexButton {
	padding-right: 10px;
}

.invoice-document-alias-remove-button {
	padding-left: 5px!important;
	padding-top: 47px;
}

.invoice-preview-modal {
	.ant-modal-body {
        padding: 0;
    }
    .ant-pagination {
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}