@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?e87j9s');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?e87j9s#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?e87j9s') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?e87j9s') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?e87j9s##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-units1 {
  &:before {
    content: $icon-units1; 
  }
}
.icon-units2 {
  &:before {
    content: $icon-units2; 
  }
}
.icon-units3 {
  &:before {
    content: $icon-units3; 
  }
}
.icon-units4 {
  &:before {
    content: $icon-units4; 
  }
}
.icon-earth {
  &:before {
    content: $icon-earth; 
  }
}
.icon-beer {
  &:before {
    content: $icon-beer; 
  }
}
.icon-can {
  &:before {
    content: $icon-can; 
  }
}
.icon-presentation {
  &:before {
    content: $icon-presentation; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-flag2 {
  &:before {
    content: $icon-flag2; 
  }
}
.icon-strategy {
  &:before {
    content: $icon-strategy; 
  }
}
.icon-checkered-flag {
  &:before {
    content: $icon-checkered-flag; 
  }
}
.icon-my_location {
  &:before {
    content: $icon-my_location; 
  }
}
.icon-sim_card {
  &:before {
    content: $icon-sim_card; 
  }
}
.icon-battery_alert {
  &:before {
    content: $icon-battery_alert; 
  }
}
.icon-flash_off {
  &:before {
    content: $icon-flash_off; 
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute; 
  }
}
.icon-volume {
  &:before {
    content: $icon-volume; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-player {
  &:before {
    content: $icon-player; 
  }
}
.icon-volume-high {
  &:before {
    content: $icon-volume-high; 
  }
}
.icon-volume2 {
  &:before {
    content: $icon-volume2; 
  }
}
.icon-audio2 {
  &:before {
    content: $icon-audio2; 
  }
}
.icon-speaker {
  &:before {
    content: $icon-speaker; 
  }
}
.icon-player2 {
  &:before {
    content: $icon-player2; 
  }
}
.icon-truck2 {
  &:before {
    content: $icon-truck2; 
  }
}
.icon-radio-tower {
  &:before {
    content: $icon-radio-tower; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-address-book {
  &:before {
    content: $icon-address-book; 
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-location2 {
  &:before {
    content: $icon-location2; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk; 
  }
}
.icon-redo2 {
  &:before {
    content: $icon-redo2; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-automobile {
  &:before {
    content: $icon-automobile; 
  }
}
.icon-car {
  &:before {
    content: $icon-car; 
  }
}
.icon-paper-plane-o {
  &:before {
    content: $icon-paper-plane-o; 
  }
}
.icon-send-o {
  &:before {
    content: $icon-send-o; 
  }
}
.icon-motorcycle {
  &:before {
    content: $icon-motorcycle; 
  }
}
.icon-battery {
  &:before {
    content: $icon-battery; 
  }
}
.icon-battery-4 {
  &:before {
    content: $icon-battery-4; 
  }
}
.icon-battery-full {
  &:before {
    content: $icon-battery-full; 
  }
}
.icon-battery-3 {
  &:before {
    content: $icon-battery-3; 
  }
}
.icon-battery-three-quarters {
  &:before {
    content: $icon-battery-three-quarters; 
  }
}
.icon-battery-2 {
  &:before {
    content: $icon-battery-2; 
  }
}
.icon-battery-half {
  &:before {
    content: $icon-battery-half; 
  }
}
.icon-battery-1 {
  &:before {
    content: $icon-battery-1; 
  }
}
.icon-battery-quarter {
  &:before {
    content: $icon-battery-quarter; 
  }
}
.icon-battery-0 {
  &:before {
    content: $icon-battery-0; 
  }
}
.icon-battery-empty {
  &:before {
    content: $icon-battery-empty; 
  }
}
.icon-thermometer {
  &:before {
    content: $icon-thermometer; 
  }
}
.icon-thermometer-4 {
  &:before {
    content: $icon-thermometer-4; 
  }
}
.icon-thermometer-full {
  &:before {
    content: $icon-thermometer-full; 
  }
}
.icon-thermometer-3 {
  &:before {
    content: $icon-thermometer-3; 
  }
}
.icon-thermometer-three-quarters {
  &:before {
    content: $icon-thermometer-three-quarters; 
  }
}
.icon-thermometer-2 {
  &:before {
    content: $icon-thermometer-2; 
  }
}
.icon-thermometer-half {
  &:before {
    content: $icon-thermometer-half; 
  }
}
.icon-thermometer-1 {
  &:before {
    content: $icon-thermometer-1; 
  }
}
.icon-thermometer-quarter {
  &:before {
    content: $icon-thermometer-quarter; 
  }
}
.icon-thermometer-0 {
  &:before {
    content: $icon-thermometer-0; 
  }
}
.icon-thermometer-empty {
  &:before {
    content: $icon-thermometer-empty; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-warning2 {
  &:before {
    content: $icon-warning2; 
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2; 
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o; 
  }
}
.icon-user2 {
  &:before {
    content: $icon-user2; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-trash-o {
  &:before {
    content: $icon-trash-o; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-mail-forward {
  &:before {
    content: $icon-mail-forward; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-users2 {
  &:before {
    content: $icon-users2; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-paper-plane-o2 {
  &:before {
    content: $icon-paper-plane-o2; 
  }
}
.icon-send-o2 {
  &:before {
    content: $icon-send-o2; 
  }
}
.icon-podcast {
  &:before {
    content: $icon-podcast; 
  }
}

