.job-add-update-form {
    padding: 1em!important;
}

.job-add-update-job-card {
    margin-bottom: 1em!important;
    background: #fafafa;
}

.dynamic-job-job-delete-button {
    cursor: pointer;
    position: relative;
    top: 0.35em;
    font-size: 24px;
    color: #999;
    transition: all .3s;
  }
  .dynamic-job-job-delete-button:hover {
    color: #777;
  }
  .dynamic-job-job-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

.job-full-component {
    width: 100%!important;
}

.job-add-update-buttons {
    text-align: right;
}

.job-add-update-buttons-send {
    margin-left: 1em;
}

.job-add-update-addons {
    background: #fafafa!important;
    border: 0!important;
}

.job-calendar-metadata-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.job-no-margin-border-padding {
    margin: 0!important;
    border: 0!important;
    padding: 0!important;
}

.job-comment-adder {
    .ant-form-item-control {
        line-height: 0!important;
        width: 95%;
    }
}

.job-partial-component {
    width: 95%!important;
}

.job-no-border-component {
    border: 0!important;
}

.job-search-addon-component {
    .ant-input-group-addon {
        padding: 0!important;
    }
    .ant-btn {
        border: 0!important;
        padding-left: 1em!important;
        padding-right: 1em!important;
    }
}

.job-bottom-padding {
    padding-bottom: 0.5em!important;
}

.job-right-align {
    text-align: right
}

.deviceIcon, .ant-select-dropdown-menu-item{
	.svgIcon{
		margin-right: 5px;
		width: 30px;
	}
}

.svgIcon{
    margin-right: 5px;
    width: 30px;
}

.calendar-remove-time-stamps{
	.rbc-event-label {
        display: none;
    }
}

.job-scrollable-list{
    height: 300px;
    overflow-y: auto;
    margin-bottom: 0.35em!important;
    margin-top: 0.35em!important;
}

.job-comments-expand{
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.job-hide-list{
    display: none!important;
}

.job-descriptions-no-round-corners{
    .ant-descriptions-view {
        border-radius: 0!important;
    }
}

.job-descriptions-no-bottom-border{
    .ant-descriptions-view {
        border-bottom: none!important;
    }
}

.job-descriptions-round-top-corner{
    .ant-descriptions-view {
        border-top-left-radius: 4px!important;
        border-top-right-radius: 4px!important;
    }
}

.job-descriptions-round-bottom-corner{
    .ant-descriptions-view {
        border-bottom-left-radius: 4px!important;
        border-bottom-right-radius: 4px!important;
    }
}

.job-view-reconnect-collapse{
    .ant-collapse, .ant-collapse-header, .ant-collapse-item {
        padding: 0!important;
        border-bottom: none!important;
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding: 0!important;
            }
        }
    }
}

.job-leased-device-warning{
    margin-top: 5px!important;
}
