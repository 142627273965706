.extraFilter {
    text-align: right;
    display: block;
    padding-right: 10px;
}
.table {
    .extraButtom {
        .anticon-stop {
            padding-right: 5px;
        }
    }
}

.disabledPopOver {
    padding: 8px 4px  
}
