.client-view-telephones-list {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 13em;
	background-color: #f0f2f5;
}

.client-view-telephone {
	margin-bottom: 0.5em;
}

.client-view-telephone-extension {
	margin-bottom: 0.7em;
	margin-left: 2em;
}

.client-view-single_tags {
	margin-bottom: 0.7em;
}

.client-view-tag {
	cursor: default !important;
	margin-bottom: 0.5em !important;
}

.client-activity-log-reject {
	color: red;
}

.client-steps {
	border-right: 1px solid #e8e8e8;
	padding-right: 10px !important;
	.ant-steps-dot .ant-steps-item-content {
		width: 75%;
		cursor: pointer;
	}
}

.client-add-edit-content {
	padding-left: 10px !important;
}

.client-disable-button {
	color: rgba(0, 0, 0, 0.65);
}

.client-basic-info-checkboxes {
	.ant-row {
		margin: 0%;
	}
}

.client-rules-card {
	margin-bottom: 8px !important;
}

.client-rules-card-grab {
	cursor: grab !important;
}

.client-rules-dragger-list {
	background-color: #f0f2f5;
	padding: 8px;
	border-radius: 10px;
}

.client-remove-margin-bottom {
	.ant-row {
		margin-bottom: 0px;
	}
	.ant-typography {
		margin-bottom: 0px;
	}
}

.client-rules-form-padding {
	padding-bottom: 8px;
}

.client-rules-device-padding {
	padding: 0px !important;
}

.client-rules-icon {
	font-size: 16px;
}

.client-dual-dragger-card {
	margin: 4px !important;
}

.client-dual-dragger-droppable {
	background-color: #f0f2f5;
	padding: 8px;
	border-radius: 10px;
	height: 500px;
	overflow-y: auto;
}

.client-dual-dragger-header {
	text-align: center;
}

.client-dual-dragger-search {
	width: 100% !important;
	margin-bottom: 10px !important;
}

.client-dual-dragger-content {
	text-align: center;
}

.client-rules-device-input-number {
	width: 50px !important;
	margin-left: 4px !important;
	margin-right: 4px !important;
}

.client-tiers-color-swatch {
	.ant-select-selection-selected-value {
		padding-top: 8px;
	}
}
