
.incomeReport{

    .IncomeViewButtons{
        text-align: center;
        // width: 420px;
        align-items: center;
        align-content: center;
        padding-right: 70px;
    }
    .selectBar{
        padding-left: 20px;
    }
    .entryList{
        margin: 0 auto;
        overflow: auto;
        // width: 80vh;
        height: 85vh;
        .entryCard {
            height: auto;
            .details{
                margin: 0 20px auto;
                .miniItemList {
                    font-size: 15px;
                    color: gray;
                    font-variant: tabular-nums;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: tabular-nums;
                    font-variant-east-asian: normal;
                    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                }
            }
            .entryCardTitle{
                .ant-statistic-content-value-decimal{
                    font-size: 13px;
                }
            }
            .entryCardDescription{
                .ant-statistic-content-value-decimal{
                    font-size: 13px;
                }
            }

        }
    }
}

.addEntryModal{
    .numberInput{
        padding-left: 20px;
    }
    .itemListDisplay{
        overflow-y: scroll;
        height: 220px; 
        padding-bottom: 50px;
    }
}

.category{
    .addButon{
        padding-left: 23px;
    }
}


